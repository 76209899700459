import React, { useState } from 'react'
import './SupportReply.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { fireToast } from 'common/Toster';
import { BASE_API_URL } from 'helpers/api_helper';

const SupportReply = (props) => {

    const { onHide, message, subject, messageContent, adminMessage, action } = props;
    const [newMessage, setNewMessage] = useState();

    const replySupport = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        try {
            let token = JSON.parse(localStorage.getItem("authUser")).token;
            const response = await axios.post(`${BASE_API_URL}/admin/replyAQuery`, {
                queryId: message,
                admin_message: newMessage,
            },
                {
                    headers: { Authorization: token },
                })
            if (response) {
                onHide();
            }
        } catch (error) {
            console.error('API Error:', error)
            // fireToast(error, 'Error While creating folder !')
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            replySupport(e);
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {action === 'details' ? 'Details' : 'Reply'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowWrap: 'break-word' }}>
                    <div className="mb-3">
                        <label className="d-block mb-2">Subject</label>
                        <p>{subject}</p>
                    </div>
                </Modal.Body>

                <Modal.Body style={{ overflowWrap: 'break-word' }}>
                    <div className="mb-3">
                        <label className="d-block mb-2">Message</label>
                        <p>{messageContent}</p>
                    </div>
                </Modal.Body>
                {action === 'details' && (
                    <Modal.Body style={{ overflowWrap: 'break-word' }}>
                        <div className="mb-3">
                            <label className="d-block mb-2">Reply</label>
                            <p>{adminMessage || 'NA'}</p>
                        </div>
                    </Modal.Body>
                )}
                {action === 'reply' && (
                    <Modal.Body>
                        <form>
                            <div className="mb-3">
                                <label className="d-block mb-2">Reply</label>
                                <textarea className='form-control' type="text" name="" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder='Enter Reply Message' onKeyPress={handleKeyPress} style={{ height: '100px', resize: 'none' }} >
                                </textarea>
                            </div>
                            <div className="text-center">
                                <Button className='border-0' onClick={replySupport}>Send</Button>
                            </div>
                        </form>
                    </Modal.Body>
                )}
            </Modal>
        </>
    )
}

export default SupportReply
