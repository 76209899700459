import React, { useEffect, useState } from 'react'
import './SupportList'
import { Container, Row, Table, Form } from 'react-bootstrap'; // Import Form from react-bootstrap
import { supportListing } from 'services/userServices'
import { fireToast } from 'common/Toster'
import SupportReply from 'components/Modals/SupportReply/SupportReply'
import { Link } from "react-router-dom";
import pendingImg from '../../assets/images/pending.png'
import resolvedImg from '../../assets/images/resolved.png'
import { FaCheck } from 'react-icons/fa';


const SupportList = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [selectedSupport, setSelectedSupport] = useState(null); // Add state for selected support
    const [supportList, setSupportList] = useState([]);
    const [supportCount, setSupportCount] = useState([]);
    const [supportId, setSupportId] = useState();
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [query_status, setQueryStatus] = useState('');
    const [searchSupport, setSearchSupport] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Added state for total pages
    const pageLimit = 10;

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";  // Or any default value you prefer
        }
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            //   timeZoneName: 'short',
            timeZone: 'GMT',
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;

    };
    const getSupportList = async (page = 1) => {
        try {
            const resp = await supportListing(query_status, searchSupport, page, start_date, end_date)
            if (resp.status == 200) {
                setSupportCount(resp?.data)
                setSupportList(resp.data?.response)
                setTotalPages(resp.data?.totalpages); // Set the total pages from the API response
                setCurrentPage(page);
                // fireToast('success', resp.message)
            } else {
                 fireToast('error', resp?.error)
            }
        } catch (error) {
            console.log(error);
            fireToast('error', error?.response?.data?.error)
        } 
    }

    const handleGetSupportList = async () => {
        await getSupportList(currentPage);
    };
    const handleCategoryClick = (status) => {
        setQueryStatus(status);
    };

    const handleStartDateChange = async (e) => {
        const selectedStartDate = e.target.value;
        setStartDate(selectedStartDate);
        await getSupportList(currentPage); // Call API when start date is selected
    };

    // Event handler for end date change
    const handleEndDateChange = async (e) => {
        const selectedEndDate = e.target.value;
        setEndDate(selectedEndDate);
        await getSupportList(currentPage); // Call API when end date is selected
    };

    const handleClearDates = () => {
        setStartDate('');
        setEndDate('');
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getSupportList(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getSupportList(currentPage - 1);
        }
    };
    const startIndex = (currentPage - 1) * pageLimit + 1;
    const endIndex = (currentPage - 1) * pageLimit + pageLimit;

    const getPageRange = () => {
        return `${startIndex}-${endIndex}`;
    };
    const truncateText = (text, maxLength) => {
        return text?.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    useEffect(() => {
        getSupportList()
    }, [query_status, start_date, end_date, searchSupport])



    return (
        <>
            <SupportReply
                show={modalShow}
                onHide={() => setModalShow(false)}
                Message={supportId}
            />
            <React.Fragment>
                <div className="page-content margin-custom support-element">
                    <Container fluid>
                        <Row>
                            <div className="col-12 col">
                                <div className="page-title-box row align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Support Queries</h4>
                                    <div className="page-title-right d-flex gap-x-5 align-items-center justify-content-end">

                                        <button className={`text-center border border-primary rounded-3 w-100 py-2 shadow p-1 text-primary   ${query_status === '' ? 'bg-primary bg-opacity-25' : 'bg-white '}`} onClick={() => handleCategoryClick('')} style={{ marginTop: '10px' }}>
                                            All {supportCount?.all_length}
                                        </button>
                                        <button className={`text-center w-100 py-2 border border-warning rounded-3 shadow p-1 text-warning ${query_status === 'pending' ? 'bg-warning  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('pending')} style={{ marginTop: '10px' }}>
                                            Pending {supportCount?.pending_length}
                                        </button>
                                        <button className={`text-center w-100 py-2 border border-success rounded-3 shadow p-1 text-success ${query_status === 'successful' ? 'bg-success  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('successful')} style={{ marginTop: '10px' }}>
                                            Resolved {supportCount?.successful_length}

                                        </button>
                                        <input className='form-control' type="text" placeholder='Search'
                                            onChange={(e) => { setSearchSupport(e.target.value) }} style={{ marginTop: '10px' }}
                                        />
                                        <Form.Group className="mb-3">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={start_date}
                                                onChange={handleStartDateChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={end_date}
                                                onChange={handleEndDateChange}
                                            />
                                        </Form.Group>

                                        <button className='btn btn-primary border-0' style={{ marginTop: '10px' }}   onClick={handleClearDates}>Clear</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-4 mb-3">
                                    <button className={`text-center border border-primary rounded-3 w-100 py-2 shadow p-1 text-primary   ${query_status === '' ? 'bg-primary bg-opacity-25' : 'bg-white '}`} onClick={() => handleCategoryClick('')}>
                                        All {supportCount?.all_length}
                                    </button>
                                </div>
                                <div className="col-4 mb-3">
                                    <button className={`text-center w-100 py-2 border border-warning rounded-3 shadow p-1 text-warning ${query_status === 'pending' ? 'bg-warning  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('pending')}>
                                        Pending {supportCount?.pending_length}
                                    </button>
                                </div>
                                <div className="col-4 mb-3">
                                    <button className={`text-center w-100 py-2 border border-success rounded-3 shadow p-1 text-success ${query_status === 'successful' ? 'bg-success  bg-opacity-25' : 'bg-white'}`} onClick={() => handleCategoryClick('successful')}>
                                        Resolved {supportCount?.successful_length}

                                    </button>
                                </div>
                            </div> */}
                            <div className="col-12">

                                <Table className=" table-nowrap align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "100px" }}>
                                                #
                                            </th>
                                            <th scope="col">Ticket Id</th>
                                            <th scope="col">Requester Name</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                            {/* <th scope="col">List</th> */}
                                        </tr>
                                    </thead>
                                    {supportList.length === 0 ? (
                                        <p>No Data Found</p>
                                    ) : (
                                        <tbody>
                                            {
                                                supportList?.map((val, i) => (
                                                    <tr key={i}>
                                                        <td>{startIndex + i}</td>
                                                        {val?.status === 'successful' && (
                                                            <td>
                                                                <a className="link-blue" onClick={() => {
                                                                    setModalAction('details');
                                                                    setModalShow(true);
                                                                    setSelectedSupport(val, 'details');
                                                                }}>{val?.ticket_id || 'NA'}</a>
                                                            </td>
                                                        )}

                                                        {val?.status === 'pending' && (
                                                            <td>
                                                                {val?.ticket_id || 'NA'}
                                                            </td>
                                                        )}


                                                        <td>{val?.user_details[0]?.firstName} {val?.user_details[0]?.lastName}</td>
                                                        <td>
                                                            <p>{truncateText(val.title, 20)}</p>
                                                        </td>
                                                        <td>
                                                            <p>{truncateText(val.message, 20)}</p>
                                                        </td>
                                                        <td>
                                                            {formatDate(val?.createdAt)}
                                                        </td>
                                                        {/* <td style={{ color: val?.status === 'successful' ? '#52AA60' : val?.status === 'pending' ? '#E7872B' : 'black', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                                        {val?.status === 'successful' && <img src={resolvedImg} alt="resolved Img" id="resolvedImg" className='status-icon' />}
                                                        {val?.status === 'pending' && <img src={pendingImg} alt="pending Img" id="pendingImg" className='status-icon' />}
                                                        {val?.status}
                                                    </td> */}
                                                        <td style={{ fontWeight: 'bold' }}>
                                                            {val?.status === 'successful' && (
                                                                <div className="d-flex align-items-center">
                                                                    <img src={resolvedImg} alt="resolved Img" className='status-icon' style={{ marginRight: '5px' }} />
                                                                    <span style={{ color: '#52AA60' }}>Resolved</span>
                                                                </div>
                                                            )}
                                                            {val?.status === 'pending' && (
                                                                <div className="d-flex align-items-center">
                                                                    <img src={pendingImg} alt="pending Img" className='status-icon' style={{ marginRight: '5px' }} />
                                                                    <span style={{ color: '#E7872B' }}>Pending</span>
                                                                </div>
                                                            )}
                                                            {val?.status !== 'successful' && val?.status !== 'pending' && (
                                                                <span style={{ color: 'black' }}>{val?.status}</span>
                                                            )}
                                                        </td>
                                                        {/* <td><button className='btn btn-primary border-0'
                                                        onClick={() => { setModalShow(true); setSupportId(val?._id) }}>Reply</button></td> */}


                                                        <td>
                                                            {val?.status === 'successful' ? (
                                                                <div>
                                                                    <button
                                                                        className='btn btn-success border-0'
                                                                        disabled
                                                                        style={{ pointerEvents: 'none' }}
                                                                    >
                                                                        Replied
                                                                        {/* <FaCheck style={{ color: 'green', marginLeft: '5px' }} /> */}
                                                                    </button>

                                                                </div>
                                                            ) : (
                                                                <button
                                                                    className='btn btn-primary border-0'
                                                                    onClick={() => {
                                                                        setModalAction('reply'); setModalShow(true); setSelectedSupport(val);
                                                                    }}>
                                                                    Reply
                                                                </button>
                                                            )}
                                                        </td>
                                                        {/* <td>
                                                        <Link to={`/document-listing/${val?._id}`} className='btn btn-primary border-0'>
                                                            View
                                                        </Link>
                                                    </td> */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    )}
                                </Table>

                            </div>
                            <div className="col-12">
                                <div className="">
                                    {/* {supportList?.length > 10 && ( */}
                                    <a>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handlePreviousPage}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <span style={{ marginLeft: '10px' }}>
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleNextPage}
                                                disabled={currentPage === totalPages}
                                            >
                                                Next
                                            </button>
                                        </span>
                                    </a>
                                    {/* )}  */}
                                </div>
                            </div>
                            <SupportReply
                                show={modalShow}
                                onHide={() => {
                                    setModalShow(false);
                                    setSelectedSupport(null); // Reset selected support item when hiding modal
                                    setModalAction(null); // Reset modal action
                                    handleGetSupportList();
                                }}
                                message={selectedSupport?._id}
                                subject={selectedSupport?.title}
                                messageContent={selectedSupport?.message}
                                adminMessage={selectedSupport?.admin_message}
                                action={modalAction}
                            />

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default SupportList