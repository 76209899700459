import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row, Container, CardTitle, Label, Form, Input, FormFeedback } from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'
import { changePassword } from "services/userServices";

const Dashboard = props => {

  //meta title
  document.title = "Change Password";

  // const [formValues, setFormValues] = useState({ oldPassword: '', password: '', confirm_password: '' })

  // const changeValues = (event) => {
  //   setFormValues({ ...formValues, [event.target.name]: event.target.value })
  // }

  const history = useHistory();

  const [passwordVisibility, setPasswordVisibility] = React.useState({
    oldPassword: false,
    newPassword: false,
    confirm: false,
  });


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
      // .email("Invalid User")
      .required("This Field is Required"),
      newPassword: Yup.string()
        // .email("Invalid User")
        .required("This Field is Required"),
      newPassword: Yup
        .string()
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
      confirm: Yup
        .string()
        .oneOf([Yup.ref('newPassword'), null], 'Must match "new password" field value'),
    }),
    onSubmit: async (values) => {
      console.log(values);
      try {
        // changeApiStatus(true, "");
        const obj = JSON.parse(localStorage.getItem("authUser"))
        const loginResponse = await changePassword(values.oldPassword,values.newPassword,obj.token);
        if (loginResponse.status === 200) {
          console.log(loginResponse, "loginResponse");
          // fireToast('success', loginResponse.message)
          history.push("/admin/dashboard");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        // changeApiStatus(false, "");
      }
    },
  });


  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Change Password")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>

                  <Form className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Old Password</Label>
                          <div className="password-input-wrapper">
                            <Input
                              type={passwordVisibility.oldPassword ? "text" : "password"}
                              name="oldPassword"
                              className="form-control"
                              id="old-password"
                              placeholder="Enter Your Password"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                            />

                            <i
                              className={`mdi ${passwordVisibility.oldPassword ? "mdi-eye" : "mdi-eye-off"
                                } password-toggle-icon eye-icon`}
                              onClick={() =>
                                setPasswordVisibility((prev) => ({
                                  ...prev,
                                  oldPassword: !prev.oldPassword,
                                }))
                              }
                            />



                          </div>
                          {validation.touched.oldPassword && validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">New Password</Label>
                          <div className="password-input-wrapper">
                            <Input
                              type={passwordVisibility.newPassword ? "text" : "password"}
                              name="newPassword"
                              className="form-control"
                              id="new-password"
                              placeholder="Enter Your Password"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                            />
                            <i
                              className={`mdi ${passwordVisibility.newPassword ? "mdi-eye" : "mdi-eye-off"
                                } password-toggle-icon eye-icon`}
                              onClick={() =>
                                setPasswordVisibility((prev) => ({
                                  ...prev,
                                  newPassword: !prev.newPassword,
                                }))
                              }
                            />
                          </div>
                          {validation.touched.newPassword && validation.errors.newPassword ? (
                            <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Confirm Password</Label>
                          <div className="password-input-wrapper">
                            <Input
                              type={passwordVisibility.confirm ? "text" : "password"}
                              name="confirm"
                              className="form-control"
                              id="confirm-password"
                              placeholder="Enter Your Password"
                              onBlur={validation.handleBlur} onChange={validation.handleChange}
                            />
                            <i
                              className={`mdi ${passwordVisibility.confirm ? "mdi-eye" : "mdi-eye-off"
                                } password-toggle-icon eye-icon`}
                              onClick={() =>
                                setPasswordVisibility((prev) => ({
                                  ...prev,
                                  confirm: !prev.confirm,
                                }))
                              }
                            />
                          </div>
                          {validation.touched.confirm && validation.errors.confirm ? (
                            <FormFeedback type="invalid">{validation.errors.confirm}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md mt-3">
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
