import React, { useState } from 'react'
import './AliasRemane.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { fireToast } from 'common/Toster';
import { BASE_API_URL } from 'helpers/api_helper';

const AliasRemane = (props) => {
    
    const { Domain } = props;
    const [newName, setNewName] = useState();
    
    const renameAlias = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        try {
            let token = JSON.parse(localStorage.getItem("authUser")).token;
            const response = await axios.put(`${BASE_API_URL}/admin/renameAlias`,  {
                domain_id: Domain,
                new_name: newName,
              },
              {
                headers: { Authorization: token },
              } )
            if (response) {
                props.onHide()
                window.location.reload()
                // props.setDomainList()
            }
        } catch (error) {
            console.error('API Error:', error)
            fireToast('error', error?.response?.data?.error)
        }
    }
    
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      renameAlias(e);
    }
  };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Set Alias
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label className="d-block mb-2">New Alias Name</label>
                            <input className='form-control' type="text" name="" value={newName} onChange={(e) => setNewName(e.target.value)} placeholder='Enter Alias Name'  onKeyPress={handleKeyPress} />
                        </div>
                        <div className="text-center">
                            <Button className='border-0' onClick={renameAlias}>Change</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AliasRemane