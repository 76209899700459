import axios from "axios";
import { downloadFile } from "common/downLoad";
import { fireToast } from "common/Toster";

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = "https://apis.u-vault.io/api";
export const BASE_API_URL = "https://apis.u-vault.io/api/";
export const REACT_APP_MEDIA_URL="https://apis.u-vault.io/uploads/";

// const API_URL = "https://uvault-apis.zip2box.com/api/";
// export const BASE_API_URL = "https://uvault-apis.zip2box.com/api/";
// export const REACT_APP_MEDIA_URL="https://uvault-apis.zip2box.com/uploads/";



export const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function getDashboard(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}
export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}
export async function getWithExportFile(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.status && !response.data.data) {
      const fileUrl = URL.createObjectURL(
        new Blob([response.data], { type: `text/csv` })
      );
      downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
    }
    return response.data;
  });
}

export async function postWithExportFile(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
      if (response.status && !response.data.data) {
        const fileUrl = URL.createObjectURL(
          new Blob([response.data], { type: `text/csv` })
        );
        downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
      }
      return response.data;
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response?.data);
}

export async function postAsForm(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}
export async function putAsForm(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, id, config = {}) {
  return await axiosApi
    .delete(
      url,
      {
        params: { id },
      },
      { ...config }
    )
    .then((response) => response.data);
}

export const apiHeader = () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  console.log(obj,'obj>>>>');
  if (obj && obj.token) {
    axiosApi.defaults.headers.common["Authorization"] = obj.token;
  } else {
    return {};
  }
};

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {

    const status = error?.response?.data?.status;
    if (status === 401) {
      localStorage.clear();
      window.location.href = "/"; 
    }

    const error1 = error?.response?.data?.error;
    if (status === 400) {
      fireToast('error', error1) 
    }

    const errore = error?.response?.data?.error;
    if (errore === "Unauthorized") {
      localStorage.clear();
      window.location.href = "/"; 
    }
    // return Promise.reject(error);
  }
);