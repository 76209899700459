import React from 'react'
import './UserDelete.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { BASE_API_URL } from 'helpers/api_helper';

const UserDelete = (props) => {
    const { userId } = props;
    const dUser = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("authUser")).token;
            const response = await axios.delete(`${BASE_API_URL}admin/deleteAUser?userId=${userId}`, {
                headers: { Authorization: token },
            })
            if (response) {
                props.onHide()
                props.getUserListing()
            }
        } catch (error) {
            console.error('API Error:', error)
        }
    }
   
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={dUser}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserDelete