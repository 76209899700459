import React, { useEffect, useState } from 'react'
import './UserInfo.css'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Table,
    UncontrolledButtonDropdown,
} from "reactstrap";

import UserDetails from 'components/Modals/UserDetails/UserDetails';
import { userListing } from 'services/userServices';
import { BsFilter, BsSortAlphaDown } from "react-icons/bs";
import { Dropdown } from 'react-bootstrap';
import UserDelete from 'components/Modals/userDelete/UserDelete';
import axios from 'axios';
import { BASE_API_URL, REACT_APP_MEDIA_URL } from 'helpers/api_helper';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { fireToast } from 'common/Toster'

const UserInfo = (props) => {
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            //   timeZoneName: 'short',
            timeZone: 'GMT',
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;

    };
    const MEDIA_URL = REACT_APP_MEDIA_URL
    const [modalShow, setModalShow] = React.useState(false);
    const [deleteShow, setDeleteShow] = React.useState(false);

    const [userList, setUserList] = useState([])
    const [userId, setUserId] = useState("")
    const [deleteuserId, setDeleteUserId] = useState("")
    const [userSearch, setUserSearch] = useState("")
    const [sort, setSort] = useState("")
    const [filter, setFilter] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Added state for total pages
    const pageLimit = 10;

    const handleSelect = (eventKey) => {
        setSort(eventKey);
    };

    const handleFilter = (eventKey) => {
        setFilter(eventKey);
    };

    const getUserListing = async (page = 1) => {
        try {
            const resp = await userListing(userId, sort, filter, userSearch, page, deleteuserId)
            if (resp.status == 200) {
                // setFolderName('');
                // handleClose();
                setUserList(resp.data.users)
                setTotalPages(resp.data.totalpages); // Set the total pages from the API response
                setCurrentPage(page);
                // fireToast('success', resp.message)
                // window.location.reload();
            } else {
                fireToast('error', resp.error)
            }
        } catch (error) {
            console.log(error);
            fireToast('error', error?.response?.data?.error)
        }
    }
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getUserListing(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getUserListing(currentPage - 1);
        }
    };
    const startIndex = (currentPage - 1) * pageLimit + 1;
    const endIndex = (currentPage - 1) * pageLimit + pageLimit;

    useEffect(() => {
        getUserListing()
    }, [ sort, filter, userSearch, deleteuserId])


    const handleBlockUnblockConfirmation = async (userId, isBlocked) => {
        const action = isBlocked ? 'Unblock' : 'Block';

        const confirmationText = `Are you sure you want to ${action.toLowerCase()} this user?`;

        Swal.fire({
            title: `${action} User`,
            text: confirmationText,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText:  `Yes, ${action} it!`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let token = JSON.parse(localStorage.getItem("authUser")).token;
                    // Assuming you have an endpoint like `${BASE_API_URL}admin/block-unblock`
                    const response = await axios.post(
                        `${BASE_API_URL}admin/block-unblock`,
                        { id: userId, isBlocked: !isBlocked },
                        {
                            headers: { Authorization: token },
                        }
                    );
                    getUserListing();
                } catch (error) {
                    console.error('API Error:', error);
                }
            } else if (result.isDismissed) {
                // User clicked Cancel
                console.log("User clicked Cancel");
            }
        });
    };


    return (
        <>

            {modalShow && <UserDetails
                show={modalShow}
                userId={userId}
                onHide={() => setModalShow(false)}
            />}

            {deleteShow && <UserDelete
                show={deleteShow}
                userId={userId}
                getUserListing={getUserListing}
                onHide={() => setDeleteShow(false)}
            />}
            <div className="col-12 col">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0 font-size-18">Users List</h4>
                    <div className="page-title-right d-flex gap-x-5 align-items-center justify-content-end">
                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Toggle className="btn btn-primary" id="sort">
                                <span>Sort by</span> <BsSortAlphaDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <Dropdown.Item eventKey={"ascending"}>
                                    Ascending
                                </Dropdown.Item>
                                <Dropdown.Item eventKey={'descending'}>Descending</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown onSelect={handleFilter}>
                            <Dropdown.Toggle className="btn btn-primary" id="filter">
                                <span>Filters</span> <BsFilter />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={""}>All</Dropdown.Item>
                                <Dropdown.Item eventKey={"Basic"}>Basic </Dropdown.Item>
                                <Dropdown.Item eventKey={"Ultra"}>Ultra</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <input className='form-control w-50' type="text" placeholder='Search' onChange={(e) => {
                            setUserSearch(e.target.value)
                        }} />
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "100px" }}>
                                #
                            </th>
                            <th scope="col">User Icon</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">User Type</th>
                            <th scope="col">Date of Joining</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    {userList.length === 0 ? (
                        <p>No Data Found</p>
                    ) : (
                        <tbody>
                            {
                                userList?.map((val, i) => (
                                    <tr style={{ opacity: val?.isDeleted ? '0.5' : "disable" }} key={i} className={val?.isDeleted === "true" ? 'bg-dark ' : "hellow"}>
                                        <td>{startIndex + i}</td>
                                        <td>
                                            <img src={MEDIA_URL + val?.profilePic} alt="" className='user-icon' />
                                        </td>
                                        <td>
                                            <strong>{val?.firstName} {val?.lastName}</strong>
                                        </td>
                                        <td>
                                            <a className='d-block' href="mailto:">{val?.email}</a>
                                        </td>
                                        <td>
                                            <a className='d-block' href="tel:{val.phoneNo}">{val?.phoneNo}</a>
                                        </td>
                                        <td>
                                            <strong className='text-danger'>{val?.plan_details[0].account_type}</strong>
                                        </td>
                                        <td>
                                            {formatDate(val?.createdAt)}
                                            {/* {formatDate(val?.createdAt, true)} */}
                                        </td>
                                        {/* <td>
                                        {val?.createdAt}
                                    </td> */}
                                        <td>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem onClick={() => {
                                                        setUserId(val?._id);
                                                        setModalShow(true);
                                                    }}>View</DropdownItem>
                                                    {/* <DropdownItem onClick={() => handleBlockUnblockConfirmation(val?._id)}>Block</DropdownItem> */}
                                                    <DropdownItem onClick={() => handleBlockUnblockConfirmation(val._id, val.isBlocked)}>
                                                        {val.isBlocked ? 'Unblock' : 'Block'}
                                                    </DropdownItem>

                                                    {/* <DropdownItem onClick={() => {
                                                        setUserId(val?._id);
                                                        setDeleteShow(true);
                                                    }}
                                                    >Delete</DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    )}
                </Table>
            </div>
            <div className="col-12">
                <div className="">
                    <a>
                        <button
                            className="btn btn-primary"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span style={{ marginLeft: '10px' }}>
                            <button
                                className="btn btn-primary"
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default UserInfo