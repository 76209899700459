// import './AboutUs.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import { pageSContent } from 'services/userServices'; // Make sure this import is correct
import axios from 'axios';
import { BASE_API_URL } from 'helpers/api_helper';


const AboutUs = ({ onChange }) => {
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());

    const handleOnChange = (editorValue) => {
        setValue(editorValue);
        if (onChange) {
            onChange(editorValue.toString('html'));
        }
    };

    const updateContent = async () => {
        const htmlContent = value.toString('html');
        console.log('HTML Content:', htmlContent);
        try {
            let response = await pageSContent({ content: htmlContent, type: "aboutUs" });
            console.log(response, "<<<<<<<<>>>>>>>>>>");
        } catch (error) {

        }
    }

    useEffect(() => {
        const fetchTermAndConditions = async () => {
          try {
            // Assuming you have the necessary import for axios
            const response = await axios.get(
              `${BASE_API_URL}user/content?type=aboutUs`
            );
    
            if (response.data && response.data.data.content) {
              setValue(RichTextEditor.createValueFromString(response.data.data.content, 'html'));
            }
          } catch (error) {
            fireToast('error', error?.response?.data?.error)
          }
        };
    
        fetchTermAndConditions();
      }, []);


    return (
        <div className="page-content margin-custom">
            <Container fluid>
                <Row>
                    <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">About Us</h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <RichTextEditor value={value} onChange={handleOnChange} />
                        <div className="text-center mt-4"><button className='btn btn-primary d-inline-block w-auto' onClick={updateContent}> update</button></div>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

AboutUs.propTypes = {
    onChange: PropTypes.func,
};

export default AboutUs;
