import React, { useEffect, useState } from 'react'
import './DocumentListing'
import { Container, Row, Table } from 'react-bootstrap'
import { domainInfo } from 'services/userServices'
import { fireToast } from 'common/Toster'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';

const DocumentListing = () => {
    const { id } = useParams();
    const [domainList, setDomainList] = useState([]);
    const [domainId, setDomainId] = useState();
    const [searchDomain, setSearcDomain] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Added state for total pages
    const pageLimit = 10;
    const getDomainList = async (page=1) => {
        try {
            const resp = await domainInfo(id, searchDomain,page)
            if (resp.status == 200) {
                setDomainList(resp.data.files);
                setTotalPages(resp.data.totalpages); // Set the total pages from the API response
                setCurrentPage(page);
                // fireToast('success', resp.message)
            } else {
                 fireToast('error', resp.error)
            }
        } catch (error) {
            console.log(error);
            fireToast('error', error?.response?.data?.error)
        }
    }

    const formatBytes = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i];
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getDomainList(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getDomainList(currentPage - 1);
        }
    };
    const startIndex = (currentPage - 1) * pageLimit + 1;
    const endIndex = (currentPage - 1) * pageLimit + pageLimit;
    
    useEffect(() => {
        getDomainList()
    }, [searchDomain])
    return (
        <>
            <React.Fragment>
                <div className="page-content margin-custom">
                    <Container fluid>
                        <Row>
                            <div className="col-12 col">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Document Listing</h4>
                                    <div className="page-title-right d-flex gap-x-5 align-items-center justify-content-end">
                                        <input className='form-control' type="text" placeholder='Search'
                                            onChange={(e) => { setSearcDomain(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <Table className=" table-nowrap align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "100px" }}>
                                                #
                                            </th>
                                            <th scope="col">Id</th>
                                            <th scope="col">VaultId</th>
                                            <th scope="col">Size</th>
                                            <th scope="col">Type</th>
                                        </tr>
                                    </thead>
                                    {domainList.length === 0 ? (
                                    <p className="text-center my-4">No data found</p>
                                ) : (
                                    <tbody>
                                        {
                                            domainList?.map((val, i) => (
                                                <tr key={i}>
                                                    <td>{startIndex + i}</td>
                                                    <td>{val?._id}</td>
                                                    <td>{val?.metadata?.VaultId}</td>
                                                    <td>{formatBytes(val?.metadata?.size)}</td>
                                                   {/* <td> {val?.key.split('.').pop()}</td> */}
                                                   <td> {val?.key.substring(val?.key.lastIndexOf('.') + 1)}</td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                       )}
                                </Table>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <a>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <span style={{ marginLeft: '10px' }}>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                    </span>
                                    </a>
                                    {/* <span style={{ marginLeft: '10px' }}>Page Range: {getPageRange()}</span> */}
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default DocumentListing