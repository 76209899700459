import React from 'react'
import {
  Container,
  Row,
} from "reactstrap";

import UserImg from "../../assets/images/user.png"
import UserInfo from 'pages/UserInfo/UserInfo';

const index = () => {

  return (
    <>
      <React.Fragment>
        <div className="page-content margin-custom">
          <Container fluid>
            <Row>
              

              <div className="col-12">
                <UserInfo />
              </div>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

export default index