import React, { useEffect, useState } from 'react'
import './ContactUsListing'
import { Container, Row, Table } from 'react-bootstrap'
import { contactUsListing } from 'services/userServices'
import { markQuery } from 'services/userServices'
import { fireToast } from 'common/Toster'
import AliasRemane from 'components/Modals/aliasName/AliasRemane'
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { FaCheck } from 'react-icons/fa';

const ContactUsListing = () => {
    const [contactUsList, setContactList] = useState([]);
    const [searchDomain, setSearcDomain] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Added state for total pages
    const pageLimit = 10;
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            //   timeZoneName: 'short',
            timeZone: 'GMT',
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        return formattedDate;

    };
    const handleMarkAsReadConfirmation = (contactUsId) => {
        swal({
            title: 'Mark as Read',
            text: 'Are you sure you want to mark this message as read?',
            icon: 'info',
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                markAsRead(contactUsId);
                getContactUsList()
            }
        });
    };
    const getContactUsList = async (page = 1) => {
        try {
            const resp = await contactUsListing(searchDomain, page)
            if (resp.status == 200) {
                setContactList(resp.data.info)
                setTotalPages(resp.data.totalpages); // Set the total pages from the API response
                setCurrentPage(page);
                // fireToast('success', resp.message)
            } else {
                 fireToast('error', resp.error)
            }
        } catch (error) {
            console.log(error);
            fireToast('error', error?.response?.data?.error)
        }
    }

    const markAsRead = async (contactUsId) => {
        try {
            const resp = await markQuery(contactUsId);
            if (resp.status === 200) {
                fireToast('success', 'Message marked as read successfully');
                // You can update the setContactList state to reflect the changes in UI if needed.
            } else {
                fireToast('error', resp.error || 'Failed to mark message as read');
            }
        } catch (error) {
            console.log(error);
            fireToast('error', 'Error while marking message as read');
        }
    };
    useEffect(() => {
        getContactUsList()
    }, [searchDomain])

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            getContactUsList(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            getContactUsList(currentPage - 1);
        }
    };
    const startIndex = (currentPage - 1) * pageLimit + 1;
    const endIndex = (currentPage - 1) * pageLimit + pageLimit;

    const getPageRange = () => {
        return `${startIndex}-${endIndex}`;
    };

    return (
        <>
            <React.Fragment>
                <div className="page-content margin-custom">
                    <Container fluid>
                        <Row>
                            <div className="col-12 col">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">Contact Us</h4>
                                    <div className="page-title-right d-flex gap-x-5 align-items-center justify-content-end">
                                        <input className='form-control' type="text" placeholder='Search'
                                            onChange={(e) => { setSearcDomain(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <Table className=" table-nowrap align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "100px" }}>
                                                #
                                            </th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Created</th>
                                            {/* <th scope="col">Updated</th> */}
                                            <th scope="col">Is Read</th>
                                        </tr>
                                    </thead>
                                    {contactUsList.length === 0 ? (
                                    <p className="text-center my-4">No data found</p>
                                ) : (
                                    <tbody>
                                        {
                                            contactUsList?.map((val, i) => (
                                                <tr key={i}>
                                                    <td>{startIndex + i}</td>
                                                    <td>{val?.name}</td>
                                                    <td>{val?.email}</td>
                                                    <td>
                                                        {/* {val?.message} */}
                                                        <p style={{ whiteSpace: 'pre-wrap' }}>{val?.message}</p>
                                                    </td>
                                                    <td> {formatDate(val?.createdAt)}</td>
                                                    {/* <td>{val?.updatedAt}</td> */}
                                                    {/* <td><button className='btn btn-primary border-0' onClick={() => handleMarkAsReadConfirmation(val?._id)}>Mark as read</button></td> */}
                                                    <td>
                                                        {val?.isRead ? (
                                                            <div>
                                                                <button
                                                                    className='btn btn-success border-0'
                                                                    disabled
                                                                    style={{ pointerEvents: 'none' }}
                                                                >
                                                                    Responded
                                                                </button>
                                                                {/* <FaCheck style={{ color: 'green', marginLeft: '5px' }} /> */}
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className='btn btn-primary border-0'
                                                                onClick={() => handleMarkAsReadConfirmation(val?._id)}
                                                            >
                                                                Mark as read
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                )}
                                </Table>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <a>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <span style={{ marginLeft: '10px' }}>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                    </span>
                                    </a>
                                    {/* <span style={{ marginLeft: '10px' }}>Page Range: {getPageRange()}</span> */}
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default ContactUsListing