import React, { useEffect, useState } from 'react'
import './UserDetails.css'
import Modal from 'react-bootstrap/Modal';
import UserIcon from "assets/images/user.png";
import { FaUserCheck, FaUserLargeSlash } from "react-icons/fa6";
import { userListInfo } from 'services/userServices';

const UserDetails = (props) => {
  const { userId } = props;

  const [userInfo, setUserInfo] = useState({})
  const [loading, setLoading] = useState(true)

  const getUserInfo = async () => {
    try {
      setLoading(true)
      const resp = await userListInfo({
        params: {
          userId
        }
      })
      if (resp.status == 200) {
        // setFolderName('');
        // handleClose();
        setUserInfo(resp.data)
        fireToast('success', resp.message)
        // window.location.reload();
      } else {
        fireToast('error', resp.error)
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
      fireToast('error', error?.response?.data?.error)
    }
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  if (loading) {
    return (<p className='text-dark text-center'>Loading...</p>)
  }
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            User Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="user-icon">
              <img src={UserIcon} alt="" />
            </div>
            <div className="ps-3">
              <p className='mb-0'><strong className='user-name'>{userInfo?.firstName} {userInfo?.lastName}</strong></p>
              {
                userInfo?.emails?.map((e) => {
                  return <p key={e?._id} className='my-0'>{e?.email}</p>
                })
              }
            </div>
          </div>
          <div className="user-info tabel-responsive mt-4">
            <table className='w-100'>
              <tbody>
                <tr>
                  <th>U-vault ID</th>
                  <td>
                    {
                      userInfo?.emails?.map((e) => {
                        return <p key={e?._id} className='my-0'>{e?.email}</p>
                      })
                    }
                  </td>
                </tr>
                <tr>
                  <th>Documents</th>
                  <td>{userInfo?.no_of_documents}</td>
                </tr>
                <tr>
                  <th>Used Space</th>
                  <td>{userInfo?.space_occupied}</td>
                </tr>
                <tr>
                  <th>Verified Document</th>
                  <td>{userInfo?.verified_documents}</td>
                </tr>
                <tr>
                  <th>Folders</th>
                  <td>{userInfo?.no_of_folders}</td>
                </tr>
                <tr>
                  <th>Verified</th>
                  <td>{userInfo?.is_verified ? <FaUserCheck className='text-success' /> : <FaUserLargeSlash className='text-danger' />} </td>
                </tr>

              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserDetails