import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

import { withRouter, Link, useLocation } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/Logo.png";

import "../../assets/css/style.css";
import { useHistory } from "react-router-dom";
import { forgotPassword, otpverify } from "services/userServices";
import { fireToast } from "common/Toster";


const ForgotOtp = props => {

  //meta title
  document.title = "ForgotOtp";

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 5000);
  }, []);

  const location = useLocation();
  const history = useHistory();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem("contact-info"),
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(6, "Invalid otp")
        .max(6, "Invalid otp")
        .required("This Field is Required"),
      // otp: Yup.string().required("This Field is Required"),
    }),
    onSubmit: async (values) => {
      try {
        // changeApiStatus(true, "");
        // let email = localStorage.getItem("contact-info");
        // console.log(email,'email');
        const loginResponse = await otpverify(values);
        if (loginResponse.status === 200) {
          console.log(loginResponse, "loginResponse");
          fireToast('success', loginResponse.message)
          localStorage.setItem("authUser", loginResponse.data.token);
          history.push("/reset-password");
        } else {
          // changeApiStatus(false, "");

          throw new Error(loginResponse.error);
        }
      } catch (err) {
        // changeApiStatus(false, "");
        console.log(err, "Error");
          fireToast('error', err.response.data.error)
      }
    },
  });

  const email =   localStorage.getItem("contact-info")
  // console.log(email, "email");
  const resendOtp = async() => {
    try {
      // changeApiStatus(true, "");
      const loginResponse = await forgotPassword({email});
      if (loginResponse.status === 200) {
        // localStorage.setItem("contact-info",values.email)
        fireToast('success', loginResponse.message)
      } else {
        // changeApiStatus(false, "");

        throw new Error(loginResponse.error);
      }
    } catch (err) {
      // changeApiStatus(false, "");
        fireToast('error', err.response.data.error)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/* <i className="bx bx-home h2" /> */}
        </Link>
      </div>
      <div className="account-pages centerd-aligned py-5">

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{background: '#0b2443'}}>
                  <Row>
                    <Col xs={7}>
                      <div className=" p-4 pe-1">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">Chack the mail and enter the OTP.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/login" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4 bg-soft" style={{ height: '4.5rem', width: '5.5rem' }}>
                        <span className="avatar-title rounded border bg-white">
                          <img
                            src={logo}
                            height="45"
                            className="mt-2"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Enter Otp</Label>
                        <Input
                          name="otp"
                          className="form-control"
                          placeholder="Enter otp"
                          type="otp"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.otp || ""}
                          invalid={
                            validation.touched.otp && validation.errors.otp ? true : false
                          }
                        />
                        {validation.touched.otp && validation.errors.otp ? (
                          <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
                        ) : null}
                      </div>
                        <div className="mb-3 text-end">
                        {showButton &&  <button
                            className="border-0 bg-transparent"
                            type='button'
                            onClick={resendOtp}
                          >
                            Resend
                          </button>}
                           
                        </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn"
                          type="submit"
                          style={{background: '#22242e', border: '#181920'}}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotOtp);

ForgotOtp.propTypes = {
  history: PropTypes.object,
};
