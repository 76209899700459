import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../components/Common/ChartsDynamicColor';
import { graphic } from 'echarts';

const JobWidgetCharts = ({ dataColors, series }) => {
    var areacharteathereumColors = getChartColorsArray(dataColors);

    var options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            offsetY: 10,
        },
        stroke: {
            width: [0, 0, 2, 2],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        fill: {
            opacity: [1, 1, 0.1, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        xaxis: {
            type: 'category',
            categories: getLabels(selectedKey, chartData),
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;
                }
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                   options={options}
                   series={series}
                   type="area"
                   height="46"
                   width="130"
                   className="apex-charts"
            />
        </React.Fragment>
    );
};

const StatisticsApplicationsChart = ({ dataColors, selectedKey, onFetchChartData }) => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartData, setChartData] = useState([]);
    const xAxisCategories = getLabels(selectedKey, chartData);


    
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const resp = await onFetchChartData(selectedKey);
                if (resp) {
                    setChartData(resp.final);
                } else {
                    // Handle error
                    console.error('Invalid response:', resp);
                }
            } catch (error) {
                console.error('Error fetching chart data:', error);
                // Handle error
            }
        };

        fetchChartData();
    }, [selectedKey]);

    useEffect(() => {
        // Update the series data based on the selectedKey
        const updatedSeries = chartData.map((entry) => entry.total);

        setChartOptions({
            ...chartOptions,
            series: [{ name: 'User', type: 'column', data: updatedSeries }],
            xaxis: { categories: getLabels(selectedKey, chartData) },
            // Update other chart options as needed
        });
        
    }, [selectedKey, chartData]);
    // Ensure that chartSeries is defined before using it
    const finalSeries = chartOptions.series || [];
    var statisticsApplicationColors = getChartColorsArray(dataColors);


    const series = [{
        name: 'Company',
        type: 'column',
        data: [30, 48, 28, 74, 39, 87, 54, 36, 50, 87, 84]
    }, {
        name: 'New Jobs',
        type: 'column',
        data: [20, 50, 42, 10, 24, 28, 60, 35, 47, 64, 78]
    }, {
        name: 'Total Jobs',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }, {
        name: 'Job View',
        type: 'line',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }];

    var options = {
        chart: {
            height: 350,
            type: 'line', // Corrected to match the series types
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            offsetY: 10,
        },
        stroke: {
            width: [0, 0, 2, 2],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '30%'
            }
        },
        fill: {
            opacity: [1, 1, 0.1, 1],
            colors: statisticsApplicationColors,
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        xaxis: {
            type: 'category',
            categories: getLabels(selectedKey, chartData),
        },
        
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;
                }
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                   options={options}
                   series={finalSeries}
                   type="line"
                   height="350"
                   className="apex-charts pb-3"
            />
        </React.Fragment>
    );
};

const ReceivedTimeCharts = ({ dataColors, selectedKeyUser, onFetchChartUserData  }) => {
    const [chartOptions, setChartOptions] = useState({});
    const [chartData, setChartData] = useState([]);
    const xAxisCategories = getLabels(selectedKeyUser, chartData);


    
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const resp = await onFetchChartUserData(selectedKeyUser);
                if (resp) {
                    setChartData(resp.final);
                } else {
                    // Handle error
                    console.error('Invalid response:', resp);
                }
            } catch (error) {
                console.error('Error fetching chart data:', error);
                // Handle error
            }
        };

        fetchChartData();
    }, [selectedKeyUser]);

    useEffect(() => {
        // Update the series data based on the selectedKeyUser
        const updatedSeries = chartData.map((entry) => entry.total);

        setChartOptions({
            ...chartOptions,
            series: [{ name: 'Document', type: 'line', color:'#0b2443', data: updatedSeries }],
            xaxis: { categories: getLabels(selectedKeyUser, chartData) },
            // Update other chart options as needed
        });
        
    }, [selectedKeyUser, chartData]);
    // Ensure that chartSeries is defined before using it
    const finalSeries = chartOptions.series || [];
    var receivedTimeColors = getChartColorsArray(dataColors);


    const series = [{
        name: 'Company',
        type: 'column',
        data: [30, 48, 28, 74, 39, 87, 54, 36, 50, 87, 84]
    }, {
        name: 'New Jobs',
        type: 'column',
        data: [20, 50, 42, 10, 24, 28, 60, 35, 47, 64, 78]
    }, {
        name: 'Total Jobs',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }, {
        name: 'Job View',
        type: 'line',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }];

    var options = {
        chart: {
            height: 350,
            type: 'line', // Corrected to match the series types
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            offsetY: 10,
        },
        stroke: {
            width: [2],
            curve: 'smooth'
        },
        // plotOptions: {
        //     bar: {
        //         columnWidth: '30%'
        //     }
        // },
        fill: {
            opacity: [1, 1, 0.1, 1],
            colors: receivedTimeColors,
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        xaxis: {
            type: 'category',
            categories: getLabels(selectedKeyUser, chartData),
        },
        
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;
                }
            }
        },
        markers: {
            size: 6, // Adjust the size of the data points
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                   options={options}
                   series={finalSeries}
                   type="line"
                   height="350"
                   className="apex-charts pb-3"
            />
        </React.Fragment>
    );
};

// Helper function to generate labels based on the selected time range
const getLabels = (selectedKey, chartData) => {
    switch (selectedKey) {
     
            case 'weekly':
                return chartData.map((entry) => {
                    const date = new Date(`${entry.year}-${entry.month}-${entry.day}`);
                    return getDayName(date.getDay());
                });
        // case 'weekly':
        //     return chartData.map((entry) => `${getDayName(entry.day)}`);
        case 'monthly':
            return chartData.map((entry) => `${getMonthName(entry.month)}`);
        case 'yearly':
            return chartData.map((entry) => entry.year);
        default:
            return [];
    }
};

// Helper function to get the day name from a numeric representation (0-6)
const getDayName = (day) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOfWeek[parseInt(day, 10)];
};

// const getDayName = (day) => {
//     const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//     return daysOfWeek[day];
// };

// Helper function to get the month name from a numeric representation (1-12)
const getMonthName = (month) => {
    const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthsOfYear[parseInt(month, 10) - 1];
};
export { JobWidgetCharts, StatisticsApplicationsChart, ReceivedTimeCharts };