import React from "react"
import { Redirect } from "react-router-dom"

// Pages User List
import Userlist from "../pages/UserList/index"
import Support from "../pages/Support/Support"
import DomainManagements from "../pages/domainManagement/DomainManagements"
import TermConditions from "../pages/termconditions/TermConditions"
import PrivacyPolicy from "../pages/privacypolicy/PrivacyPolicy"
import AboutUs from "../pages/aboutus/AboutUs"


// Profile
import UserProfile from "../pages/Authentication/user-profile"

// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ForgotOtp from "../pages/Authentication/ForgotOtp"
import PasswordReset from "../pages/Authentication/PasswordReset"

// Admin Dashboard
import AdminDashboard from "../admin/dashboard/index"
import AdminChangePassword from "../admin/account/index"
import AdminDeals from "../admin/deals/index"
import AdminDealsView from "../admin/deals/view"
import AdminDealsCreate from "../admin/deals/create"
import AdminLiquidityPools from "../admin/liquidity/index"
import DocumentListing from "pages/domainManagement/DocumentListing"
import ContactUsListing from "pages/contactUs/ContactUsListing"
import SupportList from "pages/Support/SupportList"

const authProtectedRoutes = [
   // User List
   { path: "/userlist", component: Userlist },
   { path: "/support", component: Support },
   { path: "/support-list", component: SupportList},
   { path: "/domain-managements", component: DomainManagements},
   { path: '/document-listing/:id', component: DocumentListing},
   { path: "/term-conditions", component: TermConditions},
   { path: "/about-us", component: AboutUs },
   { path: "/privacy-policy", component: PrivacyPolicy },
   { path: "/contact-us", component: ContactUsListing },
   
  //profile
  { path: "/profile", component: UserProfile },

  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/deals", component: AdminDeals },
  { path: "/admin/deals/view", component: AdminDealsView },
  { path: "/admin/deals/create", component: AdminDealsCreate },
  { path: "/admin/liquidity-pools", component: AdminLiquidityPools },

  { path: "/dashboard", component: AdminDashboard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: ForgotOtp },
  { path: "/reset-password", component: PasswordReset },

]

export { authProtectedRoutes, publicRoutes }
