import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import { Card, CardBody, Col, Row, Container, Nav, NavItem, NavLink, Table } from 'reactstrap';
import CardUser from "./CardUser";
//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'
import { StatisticsApplicationsChart } from "admin/JobCharts";
import { ReceivedTimeCharts } from "admin/JobCharts";
// Import the `series` from the JobCharts file
import { dashboard } from 'services/userServices';
import { dashboardUser } from 'services/userServices';
import { topDomainListing } from 'services/userServices';
import { fireToast } from 'common/Toster'
import { getChartsData as onGetChartsData } from "../../store/actions";
import NFTUser from "./NFT";
import Transactions from "./Transactions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";

const Dashboard = props => {
  //meta title
  document.title = "Dashboard";
  const history = useHistory();
  const BASE_PATH = process.env.REACT_APP_BASE_PATH;
  const [UserCount, setUserCount] = useState([]);
  const [domainCount, setDomainCount] = useState([]);
  const [selectedKey, setSelectedKey] = useState("weekly");
  const [selectedKeyUser, setSelectedKeyUser] = useState("weekly");



  const onFetchChartData = async (selectedKey) => {
    try {
      const resp = await dashboard(selectedKey);
      if (resp.status === 200) {
        return resp.data// Assuming the data structure matches the expected format
      } else {
        // Handle error
        console.error('Error fetching chart data:', resp.error);
        throw new Error('Failed to fetch chart data');
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      throw error;
    }
  };

  const onFetchChartUserData = async (selectedKeyUser) => {
    try {
      const resp = await dashboardUser(selectedKeyUser);
      if (resp.status === 200) {
        setUserCount(resp.data)

        return resp.data
      } else {
        // Handle error
        console.error('Error fetching chart data:', resp.error);
        throw new Error('Failed to fetch chart data');
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      throw error;
    }
  };

  const getTopDomains = async () => {
    try {
      const resp = await topDomainListing();
      if (resp.status == 200) {
        setDomainCount(resp.data.domains)
        // fireToast('success', resp.message)
      } else {
        fireToast('error', resp.error)
      }
    } catch (error) {
      console.log(error);
      fireToast('error', error?.response?.data?.error)
    }
  }


  useEffect(() => {
    getTopDomains();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}

          <Row>
            <Col xl="12">
              <CardUser resultStats="" dataColors='["--bs-primary", "--bs-warning"]' />
            </Col>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Users</h4>
                    <div className="ms-auto">
                      <Nav pills>
                        <NavItem>
                          <NavLink onClick={() => setSelectedKey("weekly")} className={selectedKey === "weekly" ? "btn-primaryChart active" : ""}>
                            Week
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink onClick={() => setSelectedKey("monthly")} className={selectedKey === "monthly" ? "btn-primaryChart active" : ""}>
                            Month
                          </NavLink>
                          {/* <NavLink href="#">Month</NavLink> */}
                        </NavItem>
                        <NavItem>
                          {/* <NavLink className="active" href="#">Year</NavLink> */}
                          <NavLink onClick={() => setSelectedKey("yearly")} className={selectedKey === "yearly" ? " btn-primaryChart active" : ""}>
                            Year
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <StatisticsApplicationsChart
                    dataColors='["#0b2443","#ff0000", "#00ff00", "#0000ff"]'
                    dir="ltr"
                    selectedKey={selectedKey}
                    onFetchChartData={onFetchChartData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            <Col xl="8">
              <Card className="h-100">
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Documents
                    <span className="userCount"> (Total no. of Doc {UserCount?.total_docs})</span>
                    </h4>
                    <div className="ms-auto">
                      <Nav pills>
                        <NavItem>
                          <NavLink onClick={() => setSelectedKeyUser("weekly")} className={selectedKeyUser === "weekly" ? "btn-primaryChart active" : ""}>
                            Week
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink onClick={() => setSelectedKeyUser("monthly")} className={selectedKeyUser === "monthly" ? "btn-primaryChart active" : ""}>
                            Month
                          </NavLink>
                          {/* <NavLink href="#">Month</NavLink> */}
                        </NavItem>
                        <NavItem>
                          {/* <NavLink className="active" href="#">Year</NavLink> */}
                          <NavLink onClick={() => setSelectedKeyUser("yearly")} className={selectedKeyUser === "yearly" ? "btn-primaryChart active" : ""}>
                            Year
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <ReceivedTimeCharts
                    dataColors='["#0b2443","#ff0000", "#00ff00", "#0000ff"]'
                    dir="ltr"
                    selectedKeyUser={selectedKeyUser}
                    onFetchChartUserData={onFetchChartUserData}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col xl="4">
              <Card className="h-100">
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Top Domain List</h4>
                  </div>
                  <div className="table-responsive">
                    <Table className=" table align-middle">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "100px" }}>
                            #
                          </th>
                          {/* <th scope="col">Domain Id</th> */}
                          <th scope="col">Domain</th>
                          <th scope="col">File Count</th>
                          {/* <th scope="col">Alias</th> */}
                        </tr>
                      </thead>
                      {domainCount.length === 0 ? (
                        <p className="text-center my-4">No data found</p>
                      ) : (
                        <tbody>
                          {
                            domainCount?.map((val, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                {/* <td>{val?.domainId}</td> */}
                                <td>{val?.domain}</td>
                                <td>{val?.file_count}</td>
                                {/* <td>{val?.alias}</td> */}
                              </tr>
                            ))
                          }

                        </tbody>
                      )}
                    </Table>
                  </div>
                  <div className="col-12">
                    <div className="">
                      <Link to={`/domain-managements`} className='btn btn-primary border-0'>
                        View All
                      </Link>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
