import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Container, Table } from "reactstrap"
import './CardUser'
//Import Images
import avatar1 from "../../assets/images/v-logo.svg"
import uvault from "../../assets/images/uVault.png"
import { JobWidgetCharts } from '../JobCharts';
import '../../assets/scss/style.css'
import { dashboard } from 'services/userServices'
import { fireToast } from 'common/Toster'

const CardUser = () => {
  const [UserCount, setUserCount] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const getUsers = async () => {
    try {
      const resp = await dashboard(selectedKey)
      if (resp.status == 200) {
        setUserCount(resp.data)
        // fireToast('success', resp.message)
      } else {
         fireToast('error', resp.error)
      }
    } catch (error) {
      console.log(error);
      fireToast('error', error?.response?.data?.error)
    }
  }

  useEffect(() => {
    getUsers()
  }, [selectedKey])

  return (
    <React.Fragment>

      <Row>
        <Col lg="12">
          <Card className="mb-2">
            <CardBody className="border">
              <Row className="e">
                <Col lg="3" className="p-0 pt-3 ps-3 m-0">
                  <div className="d-flex">
                    <div className="me-3">
                      
                      <img
                        src={avatar1}
                        alt=""
                        className="avatar-md rounded img-thumbnail p-3"
                        style={{
                          backgroundColor: "white",
                          height: "4.5rem",
                          width: "14.0rem",
                        }}
                      />
                    </div>

                  </div>
                </Col>
                <Col lg="9" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">Basic Users</p>
                              <h5 className="mb-0">{UserCount?.basic}</h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">Ultra Users</p>
                              <h5 className="mb-0"> {UserCount?.ultra} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                Corporate Users
                              </p>
                              <h5 className="mb-0"> {UserCount?.company} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="3">
                        <div className="py-4">
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2 font-size-16">
                                Total Users
                              </p>
                              <h5 className="mb-0"> {UserCount?.totalusers} </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
