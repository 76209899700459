// import { get, post, postAsForm, put } from "helpers/api_helper.js";
import appendData from "utils/formData";
import { get, getDashboard, post, postAsForm, put } from "../helpers/api_helper";

export const adminLogin = (data) => {
  return post("/admin/login", data);
};

export const forgotPassword = (data) => {
  return post("/admin/forgotPassword", data);
};

export const otpverify = (data) => {
  return post("/admin/verifyOtp", data);
};

export const resetPassword = (new_password, token) => {
  return post(
    "/admin/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

export const changePassword = (oldPassword,newPassword,token) => {
  return post(
    "/admin/changePassword",
    {oldPassword, newPassword },
    {
      headers: { Authorization: token },
    }
  );
};

export const userListing = (userId,sort,filter,userSearch,page) => {
  return get(`/admin/userListing?search=${userSearch}&filter=${filter}&date_sort=${sort}&userId=${userId}&pageNumber=${page}&pageLimit=10`);
};

export const userListInfo = (data) => {
  return get("/admin/viewAUser", data);
};

export const userDelete = (data) => {
  return get("/admin/deleteAUser", data);
};

export const domainListing = (searchDomain,page) => {
  return get(`/admin/domainListing?search=${searchDomain}&pageNumber=${page}&pageLimit=10`);
};
export const domainInfo = (id,searchDomain,page) => {
  return get(`/admin/domainInfo?domainId=${id}&search=${searchDomain}&pageNumber=${page}&pageLimit=10`);
};

export const dashboard = (selectedKey) => {
  const token = JSON.parse(localStorage.getItem("authUser")).token;
  return getDashboard(`/admin/dashboard?selected=${selectedKey}`, {headers: { Authorization: token }});
};
export const dashboardUser = (selectedKey) => {
  const token = JSON.parse(localStorage.getItem("authUser")).token;
  return getDashboard(`/admin/dashboard_docs?selected=${selectedKey}`, {headers: { Authorization: token }});
};
export const topDomainListing = (page) => {
  const token = JSON.parse(localStorage.getItem("authUser")).token;
  return getDashboard(`/admin/topDomains`, {headers: { Authorization: token }});
};
export const pageSContent = (data) => {
  return post("/admin/content", data);
};
export const contactUsListing = (searchDomain,page) => {
  return get(`/admin/contactUs?search=${searchDomain}&pageNumber=${page}&pageLimit=10`);
};
export const markQuery = (id) => {
  return get(`/admin/markQueryAsRead?contactUsId=${id}`);
};
// export const supportListing = (searchSupport, page, start_date, end_date) => {
//   return get(`/admin/userQuery?search=${searchSupport}&pageNumber=${page}&pageLimit=10&start_date=${start_date}&end_date=${end_date}`);
// };

export const supportListing = (query_status,searchSupport, page,  start_date, end_date) => {
  let queryParams = `query_status=${query_status}&search=${searchSupport}&pageNumber=${page}&pageLimit=10`;

  if (start_date) {
    queryParams += `&start_date=${start_date}`;
  }

  if (end_date) {
    queryParams += `&end_date=${end_date}`;
  }

  return get(`/admin/userQuery?${queryParams}`);
};